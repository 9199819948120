import React, { ComponentProps } from 'react';
import { FormResultProvider } from 'src/hooks/useFormResultController';
import { TopInfoBarControllerProvider } from 'src/hooks/useTopInfoBarController';
import StatsigProviderMask from '../elements/abTesting/abTesting.provider';
import { PathToProOfferOverrideProvider } from './usePathToProOfferOverride';
import { ArticleTableOfContentsProvider } from './useArticleTableOfContents';
import { HeaderDesignControllerProvider } from './useHeaderDesignController';
import { PopupControllerProvider } from './usePopupController';

const PROVIDERS = [
  FormResultProvider,
  TopInfoBarControllerProvider,
  StatsigProviderMask,
  PathToProOfferOverrideProvider,
  ArticleTableOfContentsProvider,
  HeaderDesignControllerProvider,
  PopupControllerProvider,
];

const combineProviders = (): React.FC<any> => {
  return PROVIDERS.reduce(
    (AccComponent, Provider) => {
      const AggregatedComponents = ({ children }: ComponentProps<React.FC<any>>): JSX.Element => (
        <AccComponent>
          <Provider>{children}</Provider>
        </AccComponent>
      );

      return AggregatedComponents;
    },
    ({ children }) => <>{children}</>
  );
};

const ContextProviders = combineProviders();

export default ContextProviders;
