import React, { createContext, useContext, useState } from 'react';
import { getLocalStorage, setLocalStorage } from 'src/functions/localStorageHandler';

interface FormResultControllerContextData {
  isEmailSubscriptionSubmitted: boolean;
  setFormSubmission: () => void;
}

const FormResultController = createContext<FormResultControllerContextData>({} as FormResultControllerContextData);

const FormResultProvider: React.FC<any> = ({ children }) => {
  const [isEmailSubscriptionSubmitted, setIsEmailSubscriptionSubmitted] = useState(() => {
    if (typeof window === 'undefined') return false;
    const formAlreadySubmitted = getLocalStorage('formAlreadySubmitted');

    return formAlreadySubmitted === 'true';
  });

  const setFormSubmission = () => {
    setIsEmailSubscriptionSubmitted(true);
    setLocalStorage('formAlreadySubmitted', 'true');
  };

  return (
    <FormResultController.Provider
      value={{
        isEmailSubscriptionSubmitted,
        setFormSubmission,
      }}
    >
      {children}
    </FormResultController.Provider>
  );
};

const useFormResultController = (): FormResultControllerContextData => {
  const context = useContext(FormResultController);

  if (!context) {
    throw new Error('FormResultController must be used within a FormResultProvider');
  }

  return context;
};

export { useFormResultController, FormResultProvider };
