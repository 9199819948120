import React from 'react';
import {
  getLocalStorage,
  getSessionStorage,
  setLocalStorage,
  setSessionStorage,
} from 'src/functions/localStorageHandler';
import { StatsigProvider, StatsigUser } from 'statsig-react';
import { v4 } from 'uuid';

const FIRST_TOUCH_TEXT = 'first time visitor';

const StatsigProviderMask: React.FC<any> = ({ children }) => {
  const user = (() => {
    if (typeof window === 'undefined') return null;

    const user = {} as StatsigUser;

    const statsigUserId = getLocalStorage('statsigUserId');

    if (statsigUserId) {
      user.userID = statsigUserId;

      if (getSessionStorage('@statsig:firstTouch') === 'true') {
        user.custom = {
          firstTouch: FIRST_TOUCH_TEXT,
        };
      }

      return user;
    }

    const newUserId = (window as any).v4 ? (window as any).v4() : v4();
    setLocalStorage('statsigUserId', newUserId);
    setSessionStorage('@statsig:firstTouch', 'true');

    return {
      userID: newUserId as string,
      custom: {
        firstTouch: FIRST_TOUCH_TEXT,
      },
    };
  })();

  return (
    <StatsigProvider
      sdkKey={process.env.GATSBY_STATSIG_SDK_KEY || 'client-yMNyryucOI2ddIofgqohKtiJTW1PMsiovM05eN1gKDi'}
      user={user}
      waitForInitialization={false}
    >
      {children}
    </StatsigProvider>
  );
};

export default StatsigProviderMask;
