import React from 'react';

interface PopupControllerContextData {
  pause(): void;
  resume(): void;
  isPaused: boolean;
}

const PopupControllerContext = React.createContext<PopupControllerContextData>(
  {} as PopupControllerContextData
);

const PopupControllerProvider: React.FC<any> = ({ children }) => {
  const [isPaused, setIsPaused] = React.useState(false);

  const pause = () => setIsPaused(true);
  const resume = () => setIsPaused(false);

  return (
    <PopupControllerContext.Provider value={{ pause, resume, isPaused }}>
      {children}
    </PopupControllerContext.Provider>
  );
};

const usePopupController = (): PopupControllerContextData => {
  const context = React.useContext(PopupControllerContext);

  if (!context) {
    throw new Error(
      'usePopupController must be used within a PopupControllerProvider'
    );
  }

  return context;
};

export { usePopupController, PopupControllerProvider };
