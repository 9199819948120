import React, { createContext, useContext, useEffect, useState } from 'react';
import { getLocalStorage } from 'src/functions/localStorageHandler';

interface PathToProOfferOverrideContextData {
  timestamp?: number;
  expirationTime?: number;
  setTimestamp(value: number): void;
  setExpirationTime(value: number): void;
}

/*
  To override the timestamp, use the following query params on your page:

  E.g /path-to-pro-offer?ptpor=true&etx=72

  ptpor: path to pro override (resets the offer by resetting the timestamp to the current time)
  etx: expiration time for P2P offer (can be any number >= 0 and <= 240 e.g 72 hours)

  And then call the setTimestamp and setExpirationTime functions from the context provider to set the values from the params.

  If you have questions, please check src/views/path-to-pro-offer.tsx for an example
*/

const PathToProOfferOverrideContext = createContext<PathToProOfferOverrideContextData>(
  {} as PathToProOfferOverrideContextData
);

const PathToProOfferOverrideProvider: React.FC<any> = ({ children }) => {
  const [timestamp, setTimestamp] = useState<number>();
  const [expirationTime, setExpirationTime] = useState<number>();

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const sessionTimestamp = getLocalStorage('requestADemo:ostx');
    const sessionExpirationTime = getLocalStorage('requestADemo:etx');

    if (sessionTimestamp) setTimestamp(Number(sessionTimestamp));
    if (sessionExpirationTime) setExpirationTime(Number(sessionExpirationTime));
  }, []);

  return (
    <PathToProOfferOverrideContext.Provider
      value={{
        timestamp,
        expirationTime,
        setTimestamp,
        setExpirationTime,
      }}
    >
      {children}
    </PathToProOfferOverrideContext.Provider>
  );
};

const usePathToProOfferOverride = (): PathToProOfferOverrideContextData => {
  const context = useContext(PathToProOfferOverrideContext);

  if (!context) {
    throw new Error('useFormSubmissionTrackController must be used within a PathToProOfferOverrideProvider');
  }

  return context;
};

export { usePathToProOfferOverride, PathToProOfferOverrideProvider };
