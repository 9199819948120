import React, { createContext, useState } from 'react';

export interface HeaderDesignControllerContextData {
  backgroundTheme?: 'transparent' | 'dark' | 'light' | 'blurred';
  textColorTheme?: 'light' | 'dark';
  setBackgroundTheme(theme: 'transparent' | 'dark' | 'light' | 'blurred'): void;
  setTextColorTheme(theme: 'light' | 'dark'): void;
}

const HeaderDesignControllerContext = createContext<HeaderDesignControllerContextData>(
  {} as HeaderDesignControllerContextData
);

const HeaderDesignControllerProvider: React.FC<any> = ({ children }) => {
  const [backgroundTheme, setBackgroundTheme] = useState<HeaderDesignControllerContextData['backgroundTheme']>(null);
  const [textColorTheme, setTextColorTheme] = useState<HeaderDesignControllerContextData['textColorTheme']>(null);

  return (
    <HeaderDesignControllerContext.Provider
      value={{
        backgroundTheme,
        textColorTheme,
        setBackgroundTheme,
        setTextColorTheme,
      }}
    >
      {children}
    </HeaderDesignControllerContext.Provider>
  );
};

const useHeaderDesignController = (): HeaderDesignControllerContextData => {
  const context = React.useContext(HeaderDesignControllerContext);

  if (!context) {
    throw new Error('useHeaderDesignController must be used within a HeaderDesignControllerProvider');
  }

  return context;
};

export { HeaderDesignControllerProvider, useHeaderDesignController };
