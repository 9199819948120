import getCookie from './getCookie';
import setCookie from './setCookie';

/**
 * Set to localStorage based on key-value.
 * @param key string
 * @param value string
 * @returns void
 */
export const setLocalStorage = (key: string, value: string): void => {
  if (typeof window === 'undefined') return;

  try {
    window.localStorage.setItem(key, value);
  } catch (e) {
    (window as any).TrackJS?.track(`localStorage.setItem failed`);
    setCookie(key, value); // Fallback using cookies
  }
};

/**
 * Set to sessionStorage based on key-value.
 * @param key string
 * @param value string
 * @returns void
 */
export const setSessionStorage = (key: string, value: string): void => {
  if (typeof window === 'undefined') return;

  window.sessionStorage.setItem(key, value);
};

/**
 * Get from localStorage based on key.
 * @param key string
 * @returns string
 */
export const getLocalStorage = (key: string): string => {
  if (typeof window === 'undefined') return;

  try {
    return window.localStorage.getItem(key);
  } catch (e) {
    return getCookie(key);
  }
};

/**
 * Get from sessionStorage based on key.
 * @param key string
 * @returns string
 */
export const getSessionStorage = (key: string): string => {
  if (typeof window === 'undefined') return;

  return window.sessionStorage.getItem(key);
};

/**
 * Delete item from localStorage
 * @param key string
 * @returns void
 */
export const deleteLocalStorage = (key: string): void => {
  if (typeof window === 'undefined') return;

  window.localStorage.removeItem(key);
};
