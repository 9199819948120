export default (cName: string): string => {
  if (typeof document !== 'undefined') {
    const name = cName + '=';
    const cDecoded = decodeURIComponent(document.cookie); //to be careful
    const cookieArray = cDecoded.split('; ');
    let res;
    cookieArray.forEach((val) => {
      if (val.indexOf(name) === 0) {
        res = val.substring(name.length);
      }
    });
    // set Cookie uses encodeURIComponent
    return res ? decodeURIComponent(res) : null;
  }
};

