import React, { createContext, useContext, useState } from 'react';
import { TableOfContent } from 'src/functions/getTableOfContentDictionary';

interface TableOfContentConfig {
  tocData: TableOfContent[];
  hideOnDesktop?: boolean;
  customizableComponent?: () => JSX.Element;
}

interface ArticleTableOfContentsContextData extends TableOfContentConfig {
  setTableOfContentsConfig: React.Dispatch<React.SetStateAction<TableOfContentConfig>>;
}

const ArticleTableOfContentsContext = createContext<ArticleTableOfContentsContextData>(
  {} as ArticleTableOfContentsContextData
);

const ArticleTableOfContentsProvider: React.FC<any> = ({ children }) => {
  const [tableOfContentsConfig, setTableOfContentsConfig] = useState<TableOfContentConfig>({
    tocData: [],
    hideOnDesktop: false,
    customizableComponent: null,
  });

  return (
    <ArticleTableOfContentsContext.Provider
      value={{
        ...tableOfContentsConfig,
        setTableOfContentsConfig,
      }}
    >
      {children}
    </ArticleTableOfContentsContext.Provider>
  );
};

const useArticleTableOfContents = (): ArticleTableOfContentsContextData => {
  const context = useContext(ArticleTableOfContentsContext);

  if (!context) {
    throw new Error('useArticleTableOfContents must be used within a ArticleTableOfContentsProvider');
  }

  return context;
};

export { ArticleTableOfContentsProvider, useArticleTableOfContents };
